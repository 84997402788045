import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { isAdaptiveDocument, isAdaptiveForm, isDocumentComplete, } from '@rapid/adaptive-framework';
import { store, useApplicationStore, useField, useListItem } from '@rapid/sdk';
import { getListItem } from '@rapid/sdk/lib/store/list-item';
import { DateTime } from 'luxon';
import { useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { snapshot } from 'valtio';
import ChoiceSelect from '../components/choice-select/choice-select';
import { DateSelect } from '../components/date-select/date-select';
import saveAllConnections from './save-all-connections';
import { saveCurrentTask } from './store';
import IconButton from '../components/icon-button';
export function SaveButton() {
    const [appData] = useApplicationStore();
    const [itemSnap] = useListItem(appData.params.listName, appData.params.id);
    const [saving, setSaving] = useState(false);
    const onClickInner = () => {
        setSaving(true);
        saveCurrentTask(() => {
            setSaving(false);
        });
    };
    return (_jsx("li", { className: "button flex flex-column", children: _jsx(IconButton, { className: "save", icon: "fal fa-fw fa-floppy-disk", disabled: saving || itemSnap?.$state.fetching || itemSnap?.$state.saving, onClick: onClickInner, children: "Save" }) }));
}
export function CompleteButton() {
    const [appData] = useApplicationStore();
    const [itemSnap] = useListItem(appData.params.listName, appData.params.id);
    const [saving, setSaving] = useState(false);
    const onComplete = () => {
        setSaving(true);
        const appDataSnap = snapshot(store.applicationData);
        if (!!appDataSnap.params.id && appDataSnap.params.listName) {
            const item = getListItem(appDataSnap.params.listName, appDataSnap.params.id);
            let rendererId = `${item.__metadata.type}:${item.id}`;
            const schema = item.$data?.document ??
                appDataSnap?.pages?.DefaultEmbeddedPage?.[item.__metadata.type]?.document ??
                {};
            if (!isAdaptiveDocument(schema)) {
                item.status = 'Completed';
                toast.promise(item.saveAsync().finally(() => setSaving(false)), {
                    pending: 'Completing...',
                    error: 'There was an error saving the item',
                    success: 'Item completed!',
                });
                return;
            }
            if (!isAdaptiveForm(item?.$data?.document ?? {})) {
                rendererId += ':default';
            }
            if (!isDocumentComplete(rendererId)) {
                toast.error('Unable to complete task. Ensure all required fields are filled out.');
                setSaving(false);
                return;
            }
            toast.promise(saveAllConnections(rendererId, (i) => {
                i.status = 'Completed';
            }).finally(() => setSaving(false)), {
                pending: 'Completing...',
                error: 'There was an error saving the item',
                success: 'Item completed!',
            });
        }
    };
    return (_jsx("li", { className: "button flex flex-column", children: _jsx(IconButton, { className: "complete", icon: "fal fa-fw fa-check", onClick: onComplete, disabled: itemSnap?.status === 'Completed' || saving, children: "Complete" }) }));
}
export function Choice({ item: menuItem }) {
    const [appData] = useApplicationStore();
    const [itemSnap, itemProxy] = useListItem(appData.params.listName, appData.params.id);
    const field = useField(itemSnap?.__metadata.type, menuItem.key);
    const onChoiceChanged = (value) => {
        if (itemProxy?.[field?.ColumnName] === undefined) {
            return;
        }
        itemProxy[field.ColumnName] = value.Text;
    };
    const value = useMemo(function getViewValue() {
        return field?.Settings.Choices.find((choice) => choice.Text === itemSnap?.[field?.ColumnName]);
    }, [itemSnap?.[field?.ColumnName]]);
    return (_jsxs("li", { className: `choice flex flex-column ${menuItem.key.includes('priority') ? 'sm-hidden' : ''}`, children: [_jsxs("span", { className: "choice-label label", children: [menuItem.key, ":"] }), _jsx(ChoiceSelect, { value: value, placeholder: `no ${menuItem.key.toLocaleLowerCase()}`, onChange: onChoiceChanged, choices: field?.Settings.Choices }, `data-field ${menuItem.key}`)] }));
}
export function DateInput({ item: menuItem }) {
    const [appData] = useApplicationStore();
    const [itemSnap, itemProxy] = useListItem(appData.params.listName, appData.params.id);
    const field = useField(itemSnap?.__metadata.type, menuItem.key);
    const onDateChanged = (value) => {
        if (itemProxy?.[field?.ColumnName] === undefined) {
            return;
        }
        itemProxy[field.ColumnName] = value;
    };
    return (_jsx("li", { className: "date-select flex flex-column", children: _jsx(DateSelect, { label: `${field?.Title ?? 'Date'}:`, value: itemSnap?.[field?.ColumnName], onChange: onDateChanged }) }));
}
export function LastModified() {
    const [appData] = useApplicationStore();
    const [itemSnap] = useListItem(appData.params.listName, appData.params.id);
    const [date, setDate] = useState('');
    useEffect(function parseDateOnMount() {
        if (itemSnap?.modified) {
            setDate(DateTime.fromISO(itemSnap.modified).toRelativeCalendar());
        }
        else {
            setDate('');
        }
    }, [itemSnap?.modified]);
    return (_jsx("li", { className: "heading sm-hidden", children: _jsxs("p", { className: "last-modified", children: ["Last modified ", date] }) }));
}
