import { AdaptiveStore, isAdaptiveElement } from '@rapid/adaptive-framework';
import { store } from '@rapid/sdk';
import { getListItem } from '@rapid/sdk/lib/store/list-item';
import { getCurrentItem } from './utils';
// Needs to be better at removing derivable data. If Id exists, all other keys can be removed;
function trimReferenceData(elementsTable, data, depth = 0) {
    if (data instanceof FileList) {
        return undefined;
    }
    for (const key of Object.keys(data ?? {})) {
        if (depth === 0 && !elementsTable[key]) {
            data[key] = undefined;
            continue;
        }
        if (key === '_data' && data[key]?.id) {
            const id = data[key]?.id;
            data[key] = {
                id,
            };
        }
        else if (typeof data[key] === 'object') {
            data[key] = trimReferenceData(elementsTable, data[key], depth + 1);
        }
    }
    return data;
}
export default async function saveAllConnections(rendererId, preSaveFn) {
    const item = getCurrentItem();
    const connections = AdaptiveStore[rendererId]?.connections;
    const updateData = (updater) => {
        updater(AdaptiveStore[rendererId]?.state);
    };
    const schema = item.$data?.document?.body ??
        store.applicationData?.pages?.DefaultEmbeddedPage?.[item.__metadata.type]?.document?.body;
    if (!schema || !isAdaptiveElement(schema)) {
        throw new Error(`Cannot find schema for ${item.__metadata.type}/${item.id}`);
    }
    for (const connection of Object.values(connections ?? {})) {
        if (connection?.relatedConnections?.parent)
            continue;
        const save = connection.actions[`${connection.id}:save`];
        await save.event({
            list: connection.details.args.list ?? {
                listName: item.__metadata.type,
            },
            view: connection.details.args.view ?? 'All',
            connection: connection.id,
        })({
            rendererId,
            val: {},
            connections,
            updateData,
            schema,
        });
    }
    const prefill_data = trimReferenceData(AdaptiveStore[rendererId]?.elements, {
        ...(AdaptiveStore[rendererId]?.state ?? {}),
    });
    item.prefill_data = prefill_data;
    if (Array.isArray(item.embedded_form)) {
        for (const form of item.embedded_form) {
            const formItem = getListItem(form.FormRef.Type, form.FormRef.Id);
            formItem.save();
        }
    }
    preSaveFn?.(item);
    await item.saveAsync();
}
