import chroma from 'chroma-js';
export function getChoiceValue(column, value, list, pList) {
    const fields = [...list.Fields, ...(pList?.Fields ?? [])];
    const field = fields.find((field) => field.ColumnName === column);
    if (!field)
        return null;
    const choice = field.Settings?.Choices?.find((choice) => {
        if (typeof choice === 'string')
            return choice.toLowerCase() === value.toLowerCase();
        return choice.Text.toLowerCase() === value.toLowerCase();
    });
    if (!choice)
        return null;
    return typeof choice === 'string' ? choice : choice.AccentColour;
}
export function createOpposingColor(inputColor, bgColor) {
    const chromaInputColor = chroma(inputColor);
    const chromaBGColor = chroma(bgColor);
    let chromaOpposingColor = chroma(chromaInputColor);
    //0 = black; 1 = white
    const bgLuminance = chromaBGColor.luminance();
    for (let i = 0; i < 10; i++) {
        const contrast = chroma.contrast(chromaBGColor, chromaOpposingColor);
        if (contrast > 7)
            break;
        if (bgLuminance >= 0.5)
            chromaOpposingColor = chromaOpposingColor.darken(0.7);
        else
            chromaOpposingColor = chromaOpposingColor.brighten(0.7);
    }
    return chromaOpposingColor;
}
export const findListByNames = (site, listName) => {
    return site.Lists.find((list) => {
        const lc = listName.toLowerCase();
        switch (lc) {
            case list.ListName.toLowerCase():
                return true;
            case list.ListNameSingular?.toLowerCase():
                return true;
            case list.Table.toLowerCase():
                return true;
        }
    });
};
