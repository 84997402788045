import { isAdaptiveForm, isDocumentComplete, isAdaptiveDocument, } from '@rapid/adaptive-framework';
import { triggerDialog } from '@rapid/convergence';
import { store } from '@rapid/sdk';
import { getListItem } from '@rapid/sdk/lib/store/list-item';
import { toast } from 'react-toastify';
import { proxy, snapshot } from 'valtio';
import createDialog from '../components/dialog/create-dialog';
import { Choice, CompleteButton, DateInput, LastModified, SaveButton } from './menubar-elements';
import saveAllConnections from './save-all-connections';
import { canSaveDocument } from './utils';
const THEME_KEY = 'rapid::tasks::theme';
export function getLocalStorageTheme() {
    const theme = localStorage.getItem(THEME_KEY);
    if (typeof theme === 'string') {
        const html = document.querySelector('html');
        html.classList.add(theme);
    }
}
export function toggleTheme() {
    const html = document.querySelector('html');
    html.classList.toggle('dark-theme');
    if (html.classList.contains('dark-theme')) {
        localStorage.setItem(THEME_KEY, 'dark-theme');
    }
    else {
        localStorage.removeItem(THEME_KEY);
    }
}
export function saveCurrentTask(postProcessing) {
    const appDataSnap = snapshot(store.applicationData);
    if (!!appDataSnap.params.id && appDataSnap.params.listName) {
        const item = getListItem(appDataSnap.params.listName, appDataSnap.params.id);
        let rendererId = `${item.__metadata.type}:${item.id}`;
        const schema = item.$data?.document ??
            appDataSnap?.pages?.DefaultEmbeddedPage?.[item.__metadata.type]?.document ??
            {};
        if (!isAdaptiveDocument(schema)) {
            toast.promise(item.saveAsync().finally(() => postProcessing?.()), {
                pending: 'Saving...',
                error: 'There was an error saving the item',
                success: 'Item saved!',
            });
            return;
        }
        if (!isAdaptiveForm(item?.$data?.document ?? {})) {
            rendererId += ':default';
        }
        if ((!canSaveDocument(rendererId) || item.status === 'Completed') &&
            !isDocumentComplete(rendererId)) {
            toast.error('Unable to complete task. Ensure all required fields are filled out.');
            postProcessing?.();
            return;
        }
        toast.promise(saveAllConnections(rendererId).finally(() => postProcessing?.()), {
            pending: 'Saving...',
            error: 'There was an error saving the item',
            success: 'Item saved!',
        });
    }
}
/*
 * NOTE: The below collection of
 * menu items will be rendered as
 * the command bar when a task is
 * selected.
 * */
const tasksMenubar = [
    {
        type: 'item',
        key: 'save',
        titleRender: SaveButton,
        leaf: true,
    },
    {
        type: 'item',
        key: 'complete',
        titleRender: CompleteButton,
        leaf: true,
    },
    {
        type: 'separator',
        key: 'sep-1',
        leaf: true,
    },
    {
        type: 'item',
        key: 'start_date',
        titleRender: DateInput,
        leaf: true,
    },
    {
        type: 'item',
        key: 'due_date',
        titleRender: DateInput,
        leaf: true,
    },
    {
        type: 'item',
        key: 'priority',
        titleRender: Choice,
        leaf: true,
    },
    {
        type: 'item',
        key: 'status',
        titleRender: Choice,
        leaf: true,
    },
    {
        type: 'separator',
        key: 'sep-3',
        leaf: true,
    },
    {
        type: 'item',
        key: 'last-modified',
        titleRender: LastModified,
        leaf: true,
    },
];
const placeholderMenubar = [
    {
        type: 'separator',
        key: 'placeholder',
        leaf: true,
    },
];
const tasksStore = proxy({
    currentView: 'My Tasks',
    params: {},
    keyboardShortcuts: {
        'ctrl+s': () => saveCurrentTask(),
    },
    routes: {},
    menubar: [
        {
            type: 'separator',
            leaf: true,
            key: 'delete',
        },
    ],
    itemContentSidebar: null,
    farMenubar: [
        {
            type: 'item',
            iconClass: 'fal fa-fw fa-plus',
            label: 'Create',
            children: [
                {
                    type: 'item',
                    iconClass: 'fal fa-fw fa-plus',
                    label: 'Create Task',
                    leaf: true,
                    onClick() {
                        triggerDialog(createDialog, {
                            className: 'create-dialog',
                            listName: 'Tasks',
                        })();
                    },
                    key: 'create',
                },
            ],
            key: 'create',
        },
        {
            type: 'separator',
            key: 'sep-1',
            leaf: true,
        },
        {
            type: 'item',
            iconClass: 'fal fa-fw fa-moon-over-sun theme',
            leaf: true,
            onClick() {
                toggleTheme();
            },
            key: 'theme',
        },
    ],
});
export function extractUrlParams() {
    const [, , , list, idStr] = location.pathname.replace(/^\//, '').split('/');
    const id = isNaN(+idStr) ? undefined : +idStr;
    const listName = decodeURIComponent(list);
    if (listName && id) {
        store.applicationData.menubar = tasksMenubar;
        store.applicationData.ui = { sidebarOpen: false };
        const dl = document.querySelector('.DesignerLayout');
        if (dl) {
            dl.classList.toggle('DesignerLayoutCommandBar--show', true);
        }
    }
    else {
        store.applicationData.menubar = placeholderMenubar;
        store.applicationData.ui = { sidebarOpen: true };
        const dl = document.querySelector('.DesignerLayout');
        if (dl) {
            dl.classList.toggle('DesignerLayoutCommandBar--show', false);
        }
    }
    tasksStore.params = {
        id,
        listName,
    };
}
export default function initSDKStore() {
    extractUrlParams();
    if (!!tasksStore.params.listName && !!tasksStore.params.id) {
        tasksStore.menubar = tasksMenubar;
    }
    store.applicationData = tasksStore;
}
export async function getSiteTheme() {
    const [, _tenant] = location.pathname.replace(/^\//, '').split('/');
    if (!_tenant)
        return;
    if (document.querySelector('style#rapid-theme-style'))
        return;
    const style = document.createElement('style');
    style.id = 'rapid-theme-style';
    const result = await fetch(`https://cdn.rapidplatform.com/themes/${_tenant.toLocaleLowerCase()}/theme.css`).then((res) => res.text());
    style.innerHTML = result;
    document.head.appendChild(style);
}
export { tasksMenubar, placeholderMenubar };
