import { getListFieldByName } from '@rapid/sdk';
import { nanoid } from 'nanoid';
export function returnAdaptiveFieldType(fieldType) {
    switch (fieldType) {
        case 'Text':
            return 'Input.Text';
        case 'Email':
            return 'Input.Email';
        case 'Note':
            return 'Input.MultiLineText';
        case 'DateTime':
            return 'Input.DateTime';
        case 'Choice':
            return 'Input.Choice';
        case 'Lookup':
            return 'Input.Lookup';
        case 'Boolean':
            return 'Input.Boolean';
        case 'Number':
            return 'Input.Number';
        case 'Integer':
            return 'Input.WholeNumber';
        case 'Percentage':
            return 'Input.Percentage';
        case 'Currency':
            return 'Input.Currency';
        case 'MultiLookup':
            return 'Input.MultiLookup';
        case 'User':
            return 'Input.User';
        case 'Computed':
            return 'Input.Computed';
        case 'Subquery':
            return 'Input.Subquery';
        default:
            return fieldType;
    }
}
export function embeddedToAdaptiveSchema(embeddedForm, list) {
    const schema = {
        $type: 'Layout.Section',
        id: nanoid(),
        $children: embeddedForm.Fields.map((field) => {
            const rapidField = getListFieldByName(list.ListName, field.ColumnName, true);
            if (rapidField) {
                const $type = returnAdaptiveFieldType(rapidField.FieldType);
                return {
                    $type,
                    id: nanoid(),
                    dataPath: `$.${rapidField.ColumnName}`,
                    description: rapidField.Description,
                    title: rapidField.FieldType,
                    attributes: {
                        showTime: $type === 'Input.DateTime' ? field?.ShowTime : false,
                        defaultValue: rapidField.DefaultValue,
                        label: rapidField.Title,
                        listName: list.ListName,
                        columnName: rapidField.ColumnName,
                        lookupList: $type === 'Input.User' ? 'Principals' : rapidField?.Settings?.LookupList,
                        lookupField: $type === 'Input.User' ? 'display_name' : rapidField?.Settings?.LookupField,
                        lookupBindings: rapidField?.Settings?.LookupBindings,
                        choices: rapidField?.Settings?.Choices,
                        height: $type === 'Input.MultiLineText' ? 5 : undefined,
                    },
                };
            }
            else {
                return {
                    $type: field.ColumnName,
                    id: field.ColumnName,
                };
            }
        }),
    };
    return schema;
}
