import { jsx as _jsx } from "react/jsx-runtime";
import { RapidApplication } from '@rapid/sdk/lib/ui';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import MainItem from './components/main-item/main-item';
import RootLayout from './routes/root-route';
import NoItemSelected from './routes/no-item-route';
export default function App() {
    return (_jsx(DndProvider, { backend: HTML5Backend, children: _jsx(RapidApplication, { subRoutes: [
                {
                    id: 'root-layout',
                    path: '',
                    element: _jsx(RootLayout, {}),
                    children: [
                        {
                            id: 'no-item-selected',
                            index: true,
                            element: _jsx(NoItemSelected, {}),
                        },
                        {
                            id: 'main-item',
                            path: ':listName/:id',
                            element: _jsx(MainItem, {}),
                        },
                        {
                            id: 'no-match-found',
                            path: '*',
                            element: _jsx(NoItemSelected, {}),
                        },
                    ],
                },
            ] }) }));
}
