import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useState } from 'react';
import './search-input.scss';
function SearchInput(props) {
    const [search, updateSearch] = useState(props.search ?? '');
    const onChange = useCallback(function (event) {
        updateSearch(event.target.value);
        props.onChange?.(event.target.value);
    }, [props.onChange]);
    return (_jsxs("div", { className: "search-input-container flex-column full-width", children: [!!props.label && (_jsx("label", { htmlFor: props.id, className: "label", children: props.label })), _jsxs("div", { className: "search-input-wrapper flex-align-row", children: [_jsx("div", { className: "flex-center search-input-icon", children: _jsx("i", { className: "fal fa-fw fa-magnifying-glass" }) }), _jsx("input", { placeholder: "Search", title: props.title, name: "search", id: props.id, value: search, onChange: onChange, className: "search-input flex-fill", type: "search" })] })] }));
}
export default SearchInput;
