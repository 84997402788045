import { jsx as _jsx } from "react/jsx-runtime";
import '@rapid/adaptive-framework/css/framework.css';
import '@rapid/convergence/dist/convergence.css';
import '@rapid/sdk/css/sdk.css';
import ReactDOM from 'react-dom/client';
import App from './app';
import initSDKStore, { getLocalStorageTheme, getSiteTheme } from './store/store';
import './styles/index.scss';
import './styles/radiux.css';
import { connectionRegistry } from '@rapid/adaptive-framework';
import { rapidTasksConnection } from '@rapid/adaptive-connections';
import { onLogin } from '@rapid/sdk';
import getPages from './store/on-login/get-pages';
import addTaskSubTypeCreateMenuItems from './store/on-login/add-task-subtype-create-menu-items';
import { taskContextConnection } from '@rapid/adaptive-connections/lib/connections';
connectionRegistry.add(rapidTasksConnection.name, rapidTasksConnection);
connectionRegistry.add(taskContextConnection.name, taskContextConnection);
getLocalStorageTheme();
onLogin(async (innerStore) => {
    addTaskSubTypeCreateMenuItems(innerStore);
    getSiteTheme();
    await getPages(innerStore);
});
initSDKStore();
getSiteTheme();
ReactDOM.createRoot(document.getElementById('root')).render(_jsx(App, {}));
