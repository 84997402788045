import { getListByEntityType } from '@rapid/sdk/lib/store/list';
import { getListItem } from '@rapid/sdk/lib/store/list-item';
const RAPID_TASKS_APP = {
    type: 'app',
    head: {
        title: 'RAPID Tasks',
        states: { default: { label: 'Default', state: {} } },
        iconClass: 'fal fa-fw fa-list-check',
        categories: [
            {
                title: 'Default Embedded Page',
                id: 'DefaultEmbeddedPage',
                description: 'This page will be shown for any item from the selected list that does not have an adaptive document lookup',
                required: ['list'],
                schema: {
                    $type: 'Input.Tables',
                    id: 'list',
                    dataPath: '$.list',
                    attributes: { label: 'Select List' },
                },
            },
            {
                title: 'Create Page',
                id: 'CreatePage',
                description: 'This will be shown when an item from the selected list is created',
                required: ['list'],
                schema: {
                    $type: 'Input.Tables',
                    id: 'list',
                    dataPath: '$.list',
                    attributes: { label: 'Select List' },
                },
            },
        ],
    },
    body: { $type: 'Input.Tables', id: 'list', attributes: { label: 'Select List' } },
};
export default async function getPages(store) {
    const appsRes = await store.api.Lists['Adaptive Documents']['Apps'].items.getJson({
        term: 'RAPID Tasks',
    });
    let app = appsRes.value[0];
    if (!app) {
        [app] = await store.api.Lists['Adaptive Documents'].items.postJson({}, {
            document: RAPID_TASKS_APP,
        });
    }
    store.applicationData.adaptiveApp = app;
    store.applicationData.pages = {};
    const pages = await store.api.apps[app.id].getJson();
    const fetchedIds = {};
    for (const page of pages) {
        const item = getListItem('Adaptive Documents', page.adaptive_document_id);
        if (!fetchedIds[item.id]) {
            await item.getAsync();
        }
        fetchedIds[item.id] = true;
        const list = getListByEntityType(page.entity_type_id);
        if (!store.applicationData.pages[page.category]) {
            store.applicationData.pages[page.category] = {};
        }
        if (!store.applicationData.pages[page.category][list.ListName]) {
            store.applicationData.pages[page.category][list.ListName] = {};
        }
        store.applicationData.pages[page.category][list.ListName] = item;
    }
    store.applicationData.pagesFetched = true;
}
