import { jsx as _jsx } from "react/jsx-runtime";
import { useApplicationStore, useList, useListItem } from '@rapid/sdk';
import ActivityFeed from '../../activity-feed/activity-feed';
import Attachments from '../../attachments/attachments';
import Description from '../../description/description';
import EmbeddedFilesPanel from '../../embedded-files/embedded-files-panel';
import EmbeddedFormsPanel from '../../embedded-forms/embedded-forms-panel';
import AnimatedSidebar from '../../layouts/animated-sidebar/animated-sidebar';
import LinkedItems from '../../linked-items/linked-items';
import { useEffect } from 'react';
function ItemContentSidebar() {
    const [{ params }] = useApplicationStore();
    const [item, itemProxy] = useListItem(params.listName, params.id);
    const [list] = useList(item.__metadata.type);
    useEffect(function onMount() {
        itemProxy.$linkedItems.generateLinkedItems();
    }, [item?.id, item?.LinkedItems]);
    return (_jsx(AnimatedSidebar, { tabs: [
            {
                tab: 'description',
                title: 'Description',
                icon: 'fal fw-fw fa-circle-info',
                component: (_jsx("div", { className: "fill", children: item.$state.fetching ? _jsx("div", {}) : _jsx(Description, { description: item.description }) })),
                disabled: item.$state.fetching || !item.description,
            },
            {
                tab: 'linked-items',
                title: 'Linked Items',
                icon: 'fal fa-fw fa-link',
                component: (_jsx("div", { className: "fill", children: item.$state.fetching ? _jsx("div", {}) : _jsx(LinkedItems, { item: item }) })),
                disabled: item.$state.fetching || !Object.keys(item.$linkedItems?.items ?? {}).length,
            },
            {
                tab: 'embedded-forms',
                title: 'Embedded Forms',
                icon: 'fal fw-fw fa-pen-field',
                component: (_jsx("div", { className: "fill", children: item.$state.fetching ? _jsx("div", {}) : _jsx(EmbeddedFormsPanel, { item: item }) })),
                disabled: item.$state.fetching || !item.embedded_form?.length,
            },
            {
                tab: 'embedded-files',
                title: 'Embedded Files',
                icon: 'fal fw-fw fa-files',
                component: (_jsx("div", { className: "fill", children: item.$state.fetching ? (_jsx("div", {})) : (_jsx(EmbeddedFilesPanel, { item: item, itemProxy: itemProxy })) })),
                disabled: item.$state.fetching || !item.$embeddedFiles?.length,
            },
            {
                tab: 'activity-feed',
                title: 'Activity Feed',
                icon: 'fal fa-fw fa-messages',
                component: (_jsx("div", { className: "fill", children: item.$state.fetching ? _jsx("div", {}) : _jsx(ActivityFeed, { item: item, itemProxy: itemProxy }) })),
            },
            {
                tab: 'attachments',
                title: 'Attachments',
                icon: 'fal fa-fw fa-paperclip',
                component: (_jsx("div", { className: "fill", children: item.$state.fetching ? _jsx("div", {}) : _jsx(Attachments, { item: item, itemProxy: itemProxy }) })),
                disabled: item.$state.fetching,
            },
        ] }));
}
export default ItemContentSidebar;
