import { createElement } from 'react';
const voidElements = [
    'br',
    'area',
    'base',
    'col',
    'embed',
    'hr',
    'img',
    'input',
    'keygen',
    'link',
    'meta',
    'param',
    'source',
    'track',
    'wbr',
];
const textElements = ['text'];
const nodeToReactElement = (node, key, onMatch) => {
    const tagName = node.nodeName.toLowerCase().replace('#', '');
    const props = { key, ...onMatch[tagName]?.(node) };
    if (voidElements.includes(tagName)) {
        return createElement(tagName, props);
    }
    if (textElements.includes(tagName)) {
        return node.textContent;
    }
    const children = [...node.childNodes].map((child, i) => nodeToReactElement(child, i, onMatch));
    return createElement(tagName, props, children);
};
function parseHtmlFromString(htmlString, onMatch) {
    const dom = new DOMParser().parseFromString(htmlString, 'text/html').body;
    const nodes = [];
    for (let i = 0; i < dom.childNodes.length; i++) {
        nodes.push(nodeToReactElement(dom.childNodes.item(i), i, onMatch));
    }
    return nodes.length ? nodes : null;
}
export default parseHtmlFromString;
