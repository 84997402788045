import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { AdaptiveRenderer, isAdaptiveForm, remapIds, } from '@rapid/adaptive-framework';
import Show from '@rapid/adaptive-framework/lib/controls/utils/show';
import { useApplicationStore, useListItem } from '@rapid/sdk';
import { getListItem } from '@rapid/sdk/lib/store/list-item';
import { useEffect, useMemo } from 'react';
import NoDocumentFound from '../../no-document-found/no-document-found';
import './item-dashboard.scss';
async function expandTaskDocument(itemProxy) {
    const documentItem = getListItem('Adaptive Documents', itemProxy.adaptive_document_id);
    await documentItem.getAsync();
    itemProxy.$data.document = documentItem.document;
}
export default function ItemDashboard() {
    const [appData, appProxy] = useApplicationStore();
    const [item, itemProxy] = useListItem(appData.params.listName, appData.params.id);
    const rendererId = `${item.__metadata.type}:${item.id}`;
    useEffect(function getItemOnMount() {
        if (item.id) {
            itemProxy.getAsync({}, expandTaskDocument);
        }
    }, [item.id, item.__metadata.type]);
    const [defaultDoc, defaultDocIsForm] = useMemo(function returnDefaultDoc() {
        const defaultDoc = appProxy.pages?.DefaultEmbeddedPage?.[item.__metadata.type]
            ?.document;
        if (defaultDoc?.body) {
            // NOTE: If we pass in the same document with the same id's
            // the renderer is smart enough to not change things
            // which means we don't get fetch on mount or conditions correctly
            // This feels wasteful though...
            defaultDoc.body = remapIds(defaultDoc.body, {});
        }
        return [defaultDoc, isAdaptiveForm(defaultDoc ?? {})];
    }, [item.__metadata.type, item.id]);
    const doc = itemProxy?.$data?.document;
    const docIsForm = isAdaptiveForm(item?.$data?.document ?? {});
    return (_jsxs("div", { className: "item-dashboard container", children: [_jsx(Show, { when: !!item.$state.fetching === true && !item?.$data?.document, children: _jsxs("div", { className: "loading", children: [_jsx("i", { className: "fad fa-fw fa-spinner-third fa-spin" }), "Loading..."] }) }), _jsx(Show, { when: !!docIsForm && !item.$state.fetching, children: _jsx(AdaptiveRenderer, { document: doc, data: item.prefill_data, rendererId: rendererId }) }), _jsx(Show, { when: !!defaultDocIsForm && !docIsForm && !item.$state.fetching, children: _jsx(AdaptiveRenderer, { document: defaultDoc, data: item.prefill_data, rendererId: `${rendererId}:default` }) }), _jsx(Show, { when: !defaultDocIsForm && !docIsForm && !item.$state.fetching, children: _jsx(NoDocumentFound, { category: 'DefaultEmbeddedPage', listName: item.__metadata.type }) })] }));
}
