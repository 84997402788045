import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import useComposedClassName from '@rapid-platform/use-composed-class-name';
import { AdaptiveRenderer } from '@rapid/adaptive-framework';
import { generateExplorerParams, useList, useListItem, useProductUrl } from '@rapid/sdk';
import jsonpath from 'jsonpath';
import { useEffect, useMemo } from 'react';
import { embeddedToAdaptiveSchema } from './embedded-to-adaptive-forms';
function useEmbeddedForm(form, _list) {
    const [list] = useList(_list);
    const adaptiveForm = useMemo(() => embeddedToAdaptiveSchema(form, list), [list, form]);
    return adaptiveForm;
}
export default function EmbeddedForm(props) {
    const form = useEmbeddedForm(props.form, props.form.FormRef.Type);
    const [formItem, formItemProxy] = useListItem(props.form.FormRef.Type, props.form.FormRef.Id);
    const { navigate } = useProductUrl('UI_React');
    useEffect(() => {
        formItemProxy.get();
    }, []);
    const openItemInExplorer = () => {
        navigate(generateExplorerParams(formItem.__metadata.type, formItem.id));
    };
    const focus = (hasFocus) => () => {
        formItemProxy.$data.hasFocus = hasFocus;
    };
    const embeddedFormClass = useComposedClassName(function* () {
        yield 'embedded-form';
        if (formItem.$data.hasFocus) {
            yield 'focus';
        }
    }, [formItem.$data.hasFocus]);
    const onDataChange = (element, data, path, key) => {
        if (element.$type === 'Input.User') {
            console.log('User Data', data);
        }
        if (!key || key === 'data') {
            jsonpath.value(formItemProxy, path, data);
        }
    };
    return (_jsxs("div", { className: embeddedFormClass, children: [_jsx("h4", { className: "embedded-form__title", children: props.form.Title ?? _jsx("span", { className: "muted", children: "Unset" }) }), _jsx("button", { onClick: openItemInExplorer, title: 'Open in Explorer', children: _jsx("i", { className: "fal fa-fw fa-arrow-up-right-from-square" }) }), _jsx("div", { className: "embedded-form__body", onFocus: focus(true), onBlur: focus(false), children: _jsx(AdaptiveRenderer, { document: form, data: formItem, onDataChange: onDataChange, mode: "edit" }) })] }));
}
