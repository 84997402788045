import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect } from 'react';
import DropZone from 'react-dropzone';
import { useSnapshot } from 'valtio';
export default function EmbeddedFile(props) {
    const fileSnap = useSnapshot(props.fileProxy);
    const sectionSnap = useSnapshot(props.section);
    const blobExtension = fileSnap.blob?.name?.split('.').pop();
    useEffect(function getGraphDetailsOnMount() {
        if (fileSnap.DriveItemId && fileSnap.DriveId) {
            try {
                props.fileProxy.get();
            }
            catch (e) {
                if (e instanceof Error) {
                    console.error(e.message, e);
                }
            }
        }
    }, [fileSnap.DriveItemId, fileSnap.DriveId]);
    const onDownloadClick = (e) => {
        e.stopPropagation();
        e.preventDefault();
        window.open(fileSnap.$state?.graphFile?.['@microsoft.graph.downloadUrl'], '_blank');
    };
    const onRemoveBlobClick = (e) => {
        e.stopPropagation();
        e.preventDefault();
        props.fileProxy.removeBlob();
    };
    const onOpenClick = (e) => {
        e.stopPropagation();
        e.preventDefault();
        window.open(fileSnap.$state?.graphFile?.webUrl, '_blank');
    };
    const onUpload = (files) => {
        props.fileProxy.replaceBlob(files[0]);
    };
    return (_jsx(DropZone, { multiple: false, onDrop: onUpload, disabled: !!sectionSnap.Multiple, children: ({ getRootProps, getInputProps, isDragActive }) => (_jsxs("div", { ...getRootProps(), className: `embedded-file${isDragActive ? ' drag-active' : ''}`, children: [_jsx("input", { ...getInputProps() }), fileSnap.$state.progress ? (_jsx("i", { className: "fal fa-fw fa-spinner-third fa-spin" })) : (_jsx("i", { className: "fal fa-fw fa-file" })), !!fileSnap.blob && _jsx("span", { className: "blob-name", children: fileSnap.blob.name }), !!fileSnap.blob && !!sectionSnap.FileNameTemplate && (_jsxs(_Fragment, { children: [_jsx("span", { className: "renames-to", children: "renames to" }), _jsxs("span", { className: "file-name-template", children: [sectionSnap.FileNameTemplate, ".", blobExtension] })] })), !fileSnap.blob && !!fileSnap.Name && (_jsx("span", { onClick: onOpenClick, className: "file-name", children: fileSnap.Name })), _jsxs("div", { className: "actions", children: [!!fileSnap?.blob && (_jsx("button", { className: "remove-blob", onClick: onRemoveBlobClick, children: _jsx("i", { className: "fal fa-fw fa-xmark" }) })), !fileSnap.blob && !!fileSnap.DriveId && !!fileSnap.DriveItemId && (_jsx("button", { className: "download", disabled: !fileSnap.$state?.graphFile?.['@microsoft.graph.downloadUrl'], onClick: onDownloadClick, children: _jsx("i", { className: "fal fa-fw fa-download" }) }))] })] })) }));
}
