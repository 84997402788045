import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import './rapid-diff-table.scss';
function RapidDiffTable(props) {
    return (_jsxs("table", { className: "rapid-diff-table", children: [_jsx("thead", { className: "rapid-diff-table__header", children: _jsxs("tr", { className: "rapid-diff-table__row", children: [_jsx("th", { className: "rapid-diff-table__header-column", children: "Column" }), _jsx("th", { className: "rapid-diff-table__header-column", children: "From" }), _jsx("th", { className: "rapid-diff-table__header-column", children: "To" })] }) }), _jsx("tbody", { className: "rapid-diff-table__body", children: Object.keys(props.table).map((key) => {
                    const column = key;
                    const from = String(props.table[key]['old']);
                    const to = String(props.table[key]['new']);
                    return (_jsxs("tr", { className: "rapid-diff-table__row truncate", children: [_jsx("td", { className: "rapid-diff-table__row-column truncate", title: column, children: column }), _jsx("td", { className: "rapid-diff-table__row-column truncate", title: from, children: from }), _jsx("td", { className: "rapid-diff-table__row-column truncate", title: to, children: to })] }, key));
                }) })] }));
}
export default RapidDiffTable;
