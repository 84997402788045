import { routerStore } from '@rapid/router/lib';
import { store } from '@rapid/sdk';
import { getListItem } from '@rapid/sdk/lib/store/list-item';
import { getViewDataByName } from '@rapid/sdk/lib/store/view';
import { tasksMenubar } from './store';
import { AdaptiveStore } from '@rapid/adaptive-framework';
import { snapshot } from 'valtio';
export function getCurrentItem() {
    const { id, listName } = store.applicationData.params;
    return getListItem(listName, id);
}
export function setCurrentView(view) {
    store.applicationData.currentView = view;
}
export function fetchCurrentView() {
    const view = getViewDataByName('Tasks', store.applicationData.currentView);
    view.get();
}
export function openItem(listName, id) {
    // NOTE: This now happens in a subscribe in Logo.tsx
    // I'm not sure if that's where it will stay, but
    // I wanted to see if that is a way we could keep the
    // store and router in sync
    //
    // store.applicationData.params.id = id;
    // store.applicationData.params.listName = listName;
    store.applicationData.menubar = tasksMenubar;
    routerStore.router.navigate(`_${store.auth.env}/${store.auth.tenant}/${store.auth.site}/${listName}/${id}`);
}
function connectionPathMatch(reg, path) {
    if (typeof path === 'string') {
        return reg.test(path);
    }
    if (Array.isArray(path)) {
        return path.some((i) => reg.test(i.path));
    }
    return false;
}
export function canSaveDocument(rendererId) {
    const snapAppData = snapshot(store.applicationData);
    const snap = snapshot(AdaptiveStore[rendererId]);
    const { listName, id } = snapAppData.params;
    const conMatches = Object.values(snap.connections).filter((i) => i.details?.args?.list?.listName === listName);
    if (conMatches.length === 0) {
        return true;
    }
    for (const match of conMatches) {
        const idControl = (match.children ?? []).find((i) => connectionPathMatch(/\$(\.|\[")id/i, i.path));
        if (match.details.method !== 'Expected Context' &&
            snap.state[idControl?.id]?.data !== id &&
            typeof snap.state[idControl?.id]?.data === 'undefined' &&
            snap.state[match.id]?._data?.id !== id) {
            continue;
        }
        const statusControl = (match.children ?? []).find((i) => connectionPathMatch(/\$(\.|\[")status/i, i.path));
        if (snap.state[statusControl?.id]?.data === 'Completed' ||
            (typeof snap.state[statusControl?.id]?.data === 'undefined' &&
                snap.state[match.id]?._data?.status === 'Completed')) {
            return false;
        }
    }
    return true;
}
