import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { routerStore } from '@rapid/router/lib';
import { store } from '@rapid/sdk';
import rCube from '../../public/r-cube.svg';
export default function Logo() {
    const nav = useNavigate();
    const onBackToSelection = () => {
        nav('/');
    };
    useEffect(() => {
        const unSub = routerStore.router?.subscribe(({ matches }) => {
            const mostMatch = matches.pop();
            store.applicationData.params.id = mostMatch.params.id;
            store.applicationData.params.listName = mostMatch.params.listName;
        });
        return () => {
            unSub();
        };
    }, [routerStore.router]);
    return (_jsxs("div", { className: "logo-container", children: [_jsx("button", { className: "back-button", title: "Return to document selection", onClick: onBackToSelection, children: _jsx("i", { className: "fal fa-fw fa-chevron-left" }) }), _jsxs("div", { className: "logo-title", children: [_jsx("img", { style: { height: 32 }, src: rCube }), _jsx("h1", { className: "app-title", children: "Tasks" })] })] }));
}
