import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { store, useApplicationStore, useList, useListDataSources, useViewItems } from '@rapid/sdk';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FixedSizeList as List } from 'react-window';
import { useSnapshot } from 'valtio';
import { setCurrentView } from '../../store/utils';
import ChoiceSelect from '../choice-select/choice-select';
import ListItemCard from '../list-item-card/list-item-card';
import SearchInput from '../search-input/search-input';
import './task-list.scss';
const searchParam = {
    $select: 'id, assigned_to, status, priority, title, start_date, due_date',
};
export default function TasksList() {
    const snap = useSnapshot(store.applicationData);
    const listContainerRef = useRef(null);
    const resizeObserver = useRef(null);
    const listRef = useRef(null);
    const hasScrolled = useRef(false);
    const term = useRef('');
    const [list] = useList('Tasks');
    const [ds] = useListDataSources('Tasks');
    const [appData] = useApplicationStore();
    const [view, viewProxy] = useViewItems(list, appData.currentView);
    const [listHeight, setListHeight] = useState(0);
    const fetchView = () => {
        viewProxy.search(term.current, searchParam);
    };
    const dataSource = useMemo(() => ds.find((d) => d.Title === appData.currentView), [appData.currentView, ds]);
    const onSearchChange = (_term) => {
        term.current = _term;
        viewProxy.search(_term, searchParam);
    };
    const onViewChange = (view) => {
        setCurrentView(view.Title);
    };
    //TODO: implement debounce
    const onObserverChange = useCallback((entries) => {
        const entry = entries[0];
        const { height } = entry.contentRect;
        setListHeight(height);
    }, []);
    const activeIndex = useMemo(() => {
        const index = view.findIndex((i) => i.id === appData.params.id) ?? -1;
        if (index !== -1) {
            return index;
        }
    }, [appData.params.id, view.$state.fetching]);
    useEffect(function fetchViewEffect() {
        fetchView();
    }, [appData.currentView]);
    useEffect(function resizeEffect() {
        resizeObserver.current = new ResizeObserver(onObserverChange);
        resizeObserver.current.observe(listContainerRef.current);
        return () => {
            resizeObserver.current.disconnect();
        };
    }, []);
    function onItemsRendered({ visibleStopIndex, visibleStartIndex }) {
        const shouldScroll = !hasScrolled.current && !!activeIndex;
        const activeItemVisible = !!activeIndex && activeIndex <= visibleStartIndex && activeIndex >= visibleStopIndex;
        if (shouldScroll && !activeItemVisible) {
            listRef.current.scrollToItem(activeIndex, 'start');
            hasScrolled.current = true;
        }
    }
    const VirtualListItem = ({ index, style }) => {
        const item = view.atIndex(index);
        return (_jsx("div", { style: { ...style, paddingBlock: 'var(--size3)', paddingInline: 'var(--size5)' }, children: _jsx(ListItemCard, { list: list, listItem: item, onOpenClicked: () => {
                    if (!store.applicationData.ui)
                        return;
                    store.applicationData.ui.sidebarOpen = false;
                } }, item.id) }));
    };
    return (_jsxs("div", { className: "tasks-list container flex-column", "data-active": snap.ui?.sidebarOpen, children: [_jsxs("div", { className: "tasks-list__toolbar flex-align-row", children: [_jsx(SearchInput, { title: `Search ${list.ListName}`, id: "search-input", search: term.current, onChange: onSearchChange }), _jsx(ChoiceSelect, { value: dataSource, placeholder: "select view", onChange: onViewChange, choices: ds, labelKey: "Title", idKey: "Title" }), _jsx("button", { className: "button-icon", onClick: fetchView, children: _jsx("i", { className: "fal fa-fw fa-refresh" }) })] }), _jsx("div", { className: "tasks-list__content flex-column flex-fill", ref: listContainerRef, children: _jsx(List, { onItemsRendered: onItemsRendered, ref: listRef, className: "virtual-list", height: listHeight, width: "100%", itemCount: view.length(), itemSize: 120, itemKey: (index) => view.atIndex(index).id, children: VirtualListItem }) })] }));
}
