import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { getListByName } from '@rapid/sdk';
import { useState } from 'react';
import './linked-items-group.scss';
function LinkedItemsGroup(props) {
    const list = getListByName(props.listName);
    const [isOpen, setOpen] = useState(false);
    const onClick = (ev) => {
        ev.stopPropagation();
        if (!isOpen)
            props.onOpen(props.listName);
        setOpen((draft) => !draft);
    };
    const onNavigate = (id) => (ev) => {
        ev.stopPropagation();
        props.onNavigate(list, id);
    };
    if (!list)
        return null;
    return (_jsxs("details", { className: "linked-items-group", onClick: onClick, open: isOpen, children: [_jsxs("summary", { className: "linked-items-group__list", children: [props.listName, " (", props.items.length, ")"] }), _jsx("ul", { className: "linked-items-group__list-items", children: isOpen &&
                    props.items.map((item) => {
                        return (_jsxs("li", { className: "linked-items-group__list-item", children: [_jsx("p", { className: "linked-items-group__list-item__title", children: item?.title }), _jsx("button", { className: "button-icon", onClick: onNavigate(item.id), children: _jsx("i", { className: "fa-solid fa-arrow-up-right-from-square" }) })] }, item.id));
                    }) })] }));
}
export default LinkedItemsGroup;
