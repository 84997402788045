import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { DateTime } from 'luxon';
import { useMemo } from 'react';
import './date-select.scss';
export function DateSelect({ label, value, onChange }) {
    const onDateChanged = (value) => {
        if (!value?.target?.value) {
            return;
        }
        const dt = DateTime.fromISO(value.target.value);
        if (!dt.isValid) {
            return;
        }
        onChange(dt.setZone('UTC').toISO());
    };
    const date = useMemo(function onValueChanged() {
        if (!value) {
            return undefined;
        }
        const date = DateTime.fromISO(value);
        if (!date.isValid) {
            return undefined;
        }
        return date.toISODate();
    }, [value]);
    const onDateFocus = (ev) => {
        ev.currentTarget.showPicker?.();
    };
    return (_jsxs("label", { className: "date-container", children: [date && (_jsx("input", { className: "date-select", onFocus: onDateFocus, value: date, type: "date", onChange: onDateChanged })), !date && (_jsx("input", { className: "date-select", onFocus: onDateFocus, value: date, placeholder: "no date", type: "text", onChange: onDateChanged })), label && _jsxs("span", { className: "date-label label", children: [label, "\u2002"] })] }));
}
