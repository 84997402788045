import { triggerDialog } from '@rapid/convergence';
import { getListSubTypes } from '@rapid/sdk/lib/store/list';
import createDialog from '../../components/dialog/create-dialog';
function generateCreateMenuItem(menuItems, list) {
    if (list.SystemManaged) {
        return menuItems;
    }
    menuItems.push({
        type: 'item',
        iconClass: 'fal fa-fw fa-plus',
        label: `Create ${list.ListNameSingular}`,
        leaf: true,
        onClick() {
            triggerDialog(createDialog, {
                className: 'create-dialog',
                listName: list.ListName,
            })();
        },
        key: 'create',
    });
    return menuItems;
}
export default function addTaskSubTypeCreateMenuItems(store) {
    const taskSubTypes = getListSubTypes('Tasks');
    const subMenuItems = taskSubTypes?.reduce(generateCreateMenuItem, []);
    store.applicationData.farMenubar[0].children.push(...(subMenuItems ?? []));
}
