import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Show from '@rapid/adaptive-framework/lib/controls/utils/show';
import { getListByName, store } from '@rapid/sdk';
import { Input } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import './activity-feed.scss';
import TimelineItem from './ui/timeline-item/timeline-item';
const { TextArea } = Input;
function ActivityFeed(props) {
    const [value, setValue] = useState('');
    const [isSaving, setIsSaving] = useState(false);
    const timeline = useMemo(() => [...props.item.$timeline].reverse(), [props.item.$timeline.length]);
    const dataRef = `${props.item.__metadata.type}/${props.item.id}`;
    const onMatch = {
        a: () => {
            const list = getListByName(props.item.__metadata.type);
            let url = store.auth.environments[store.auth.env]?.ui_react_url;
            url += `/${store.auth.tenant}/${store.auth.site}/explorer`;
            url += `/${list.ListName}/${list.ListNameSingular}:${list.ListName}:${props.item.id}`;
            return { href: url, target: '_blank' };
        },
    };
    useEffect(function onMount() {
        props.itemProxy.getTimeline();
    }, [props.item.id]);
    const onNewMessage = (event) => {
        event.preventDefault();
        if (!event.currentTarget.body.value) {
            return;
        }
        setIsSaving(true);
        const list = getListByName(props.item.__metadata.type);
        const value = `<p>${event.currentTarget.body.value}</p>`;
        const title = `Re: ${list.ListNameSingular} <a data-item-ref="${props.item.__metadata.type}/${props.item.id}">${props.item[list.TitleField ?? 'title']}</a> &mdash; ${store.auth.account.name}`;
        store.api.Lists.Notes.Items.postJson(undefined, {
            LinkedItemsToAdd: [dataRef],
            body: value.trim(),
            title: title.trim(),
        }).then(() => {
            props.itemProxy.getTimeline();
            setValue('');
            setIsSaving(false);
        });
    };
    return (_jsxs("section", { className: "activity-feed flex-column", children: [_jsx("header", { className: "activity-feed__header", children: _jsx("h4", { className: "h4 activity-feed__header-text", children: "Activity Feed" }) }), _jsx("main", { className: "activity-feed__container flex-column flex-fill", children: timeline.map((timeline, index) => {
                    return (_jsxs(React.Fragment, { children: [_jsx("div", { className: "divider" }), _jsx(TimelineItem, { onMatch: onMatch, timeline: timeline }, timeline.key)] }, index));
                }) }), _jsx("footer", { className: "activity-feed__footer", children: _jsx(Show, { when: !isSaving, fallback: _jsxs("div", { className: "loading", children: [_jsx("i", { className: "fad fa-fw fa-spinner-third fa-spin" }), "Saving Note..."] }), children: _jsxs("form", { onSubmit: onNewMessage, className: "flex-column", children: [_jsx(TextArea, { name: "body", value: value, onChange: (e) => setValue(e.currentTarget.value), rows: 2, placeholder: "Message..." }), _jsxs("button", { className: "icon-button", children: [_jsx("i", { className: "fa-light fa-paper-plane-top" }), " Send"] })] }) }) })] }));
}
export default ActivityFeed;
