import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import EmbeddedFileSection from '../embedded-files/embedded-file-slot';
import './attachments.scss';
import { useEffect } from 'react';
import { generateEmbeddedFileSectionMixin } from '@rapid/sdk/lib/store/list-item-extensions/embedded-files/functions';
function Attachments(props) {
    const items = props.itemProxy.$embeddedFiles.filter((i) => i.Type === 'Attachment');
    useEffect(function onLoad() {
        if (!!props.item.drive_id && !!props.item.folder_id && items.length === 0) {
            props.itemProxy.$embeddedFiles.push(generateEmbeddedFileSectionMixin({
                Label: 'Attachments',
                Type: 'Attachment',
                DriveId: null,
                FolderId: null,
                FilePath: '/Attachments',
                FileNameTemplate: '',
                Files: [],
                Multiple: true,
                Required: false,
            }, props.itemProxy));
        }
    }, [props.item.drive_id, props.item.folder_id]);
    return (_jsxs("div", { className: "embedded-files-panel", children: [_jsx("header", { className: "embedded-files-panel__header", children: _jsx("h4", { className: "embedded-files-panel__title", children: "Attachments" }) }), !props.item.folder_id && (_jsxs("div", { className: "embedded-files-panel__no-drive", children: [_jsx("p", { className: "embedded-files-panel__no-drive-text", children: "This item doesn't have a folder configured, click the button below to create one." }), _jsx("button", { className: "embedded-files-panel__create-drive", onClick: () => {
                            props.itemProxy.createFolderAsync();
                        }, children: "Create Folder" })] })), _jsx("div", { className: "embedded-files-panel__files", children: items?.map((embeddedFileSlot) => (_jsx(EmbeddedFileSection, { embeddedFileSection: embeddedFileSlot }, embeddedFileSlot.$key))) })] }));
}
export default Attachments;
