import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as ToggleGroup from '@radix-ui/react-toggle-group';
import { useCallback, useState } from 'react';
import ConditionalRender from '../../conditional-render/conditional-render';
import './animated-sidebar.scss';
import IconButton from '../../icon-button';
import { store } from '@rapid/sdk';
import { useSnapshot } from 'valtio';
function AnimatedSidebar(props) {
    const snap = useSnapshot(store.applicationData);
    const [{ activeTab, open }, updateState] = useState({ activeTab: '', open: false });
    const onTabChanged = useCallback((tab) => {
        if (tab) {
            updateState({ activeTab: tab, open: true });
            return;
        }
        updateState({ activeTab, open: false });
        setTimeout(() => {
            updateState({ activeTab: '', open });
        }, 200);
    }, []);
    const onSideBarOpen = () => {
        if (!store.applicationData.ui)
            store.applicationData.ui = {};
        store.applicationData.ui.sidebarOpen = !store.applicationData.ui.sidebarOpen;
        const dl = document.querySelector('.DesignerLayout');
        if (dl) {
            dl.classList.toggle('DesignerLayoutCommandBar--show', !store.applicationData.ui.sidebarOpen);
        }
    };
    return (_jsxs("section", { className: "animated-sidebar flex-row", "data-open": !!open, children: [_jsx("main", { className: "animated-sidebar__content", children: props.tabs.map(({ tab, component }, ind) => (_jsx(ConditionalRender, { renderWhenAllTrue: [activeTab === tab], children: component }, ind))) }), _jsx(IconButton, { className: "sidebar-btn-toggle", onClick: onSideBarOpen, "data-open": !!snap.ui?.sidebarOpen, children: _jsx("i", { className: "fa-solid fa-list" }) }), _jsx(ToggleGroup.Root, { type: "single", className: "animated-sidebar__tab-buttons container flex-column", onValueChange: onTabChanged, children: props.tabs.map(({ tab, ...rest }, ind) => (_jsx(ToggleGroup.ToggleGroupItem, { asChild: true, value: tab, children: _jsx(IconButton, { ...rest }) }, ind))) })] }));
}
export default AnimatedSidebar;
