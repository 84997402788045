import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
function ConditionalRender(props) {
    if (props.renderWhenAllTrue) {
        const shouldRender = props.renderWhenAllTrue.every((v) => v);
        if (shouldRender)
            return _jsx(_Fragment, { children: props.children });
    }
    if (props.renderWhenAnyTrue) {
        const shouldRender = props.renderWhenAnyTrue.some((v) => v);
        if (shouldRender)
            return _jsx(_Fragment, { children: props.children });
    }
    return null;
}
export default ConditionalRender;
