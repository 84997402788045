import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { DesignerLayout } from '@rapid/convergence';
import { useApplicationStore } from '@rapid/sdk';
import { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import Logo from '../components/logo';
import TasksList from '../components/tasks-list/tasks-list';
import { extractUrlParams } from '../store/store';
import '../store/menubar-elements.scss';
import '../styles/routes/root-route-styles.scss';
function hasListItemId(appData) {
    return appData.params.listName && appData.params.id;
}
const placeholderMenubar = [
    {
        type: 'item',
        key: 'placeholder',
        titleRender: () => _jsx(_Fragment, {}),
        leaf: true,
    },
];
export default function ConvergenceLayout() {
    const [appData] = useApplicationStore();
    const location = useLocation();
    const menubar = hasListItemId(appData) ? appData?.menubar : placeholderMenubar;
    useEffect(function () {
        extractUrlParams();
    }, [location]);
    return (_jsx(DesignerLayout, { keyboardShortcuts: appData.keyboardShortcuts, routes: appData.routes, logo: _jsx(Logo, {}), menubar: menubar, farMenubar: appData?.farMenubar, sidebar: _jsx(TasksList, {}), children: appData.pagesFetched && _jsx(Outlet, {}) }));
}
