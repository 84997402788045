import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { AdaptiveRenderer, isDocumentComplete } from '@rapid/adaptive-framework';
import { useApplicationStore } from '@rapid/sdk';
import { createListItemAsync } from '@rapid/sdk/lib/store/list-item';
import jsonpath from 'jsonpath';
import { useMemo } from 'react';
import { toast } from 'react-toastify';
import { proxy, useSnapshot } from 'valtio';
import { fetchCurrentView, openItem } from '../../store/utils';
import Show from '../conditional-render/show';
import NoDocumentFound from '../no-document-found/no-document-found';
import './create-dialog.scss';
export default function CreateDialog(props) {
    const [appData, appProxy] = useApplicationStore();
    const [item, itemProxy] = useCreateDialogItem(props.listName);
    const rendererId = `create:${props.listName}`;
    const createPage = appProxy.pages?.CreatePage?.[props.listName]?.document;
    const createPageSnap = appData.pages?.CreatePage?.[props.listName]?.document;
    const onDataChange = (element, data, path, key) => {
        if (!key || key === 'data') {
            jsonpath.value(itemProxy, element.connection.path, data);
        }
    };
    const createItem = () => {
        if (!isDocumentComplete(rendererId)) {
            return;
        }
        toast
            .promise(createListItemAsync(item), {
            pending: `Creating ${props.listName}`,
            success: `${props.listName} created successfully`,
            error: 'An error occurred',
        })
            .then((item) => {
            fetchCurrentView();
            openItem(props.listName, item.id);
            props.onSave();
        });
    };
    return (_jsxs("div", { className: "create-dialog container", children: [_jsxs("h3", { className: "dialog-title", children: ["Create ", item.__metadata.type] }), _jsx(Show, { when: !!createPageSnap, fallback: _jsx(NoDocumentFound, { category: 'CreatePage', listName: props.listName }), children: _jsx(AdaptiveRenderer, { rendererId: rendererId, document: createPage, data: item, onDataChange: onDataChange }) }), _jsxs("div", { className: "dialog-actions", children: [_jsx("button", { onClick: props.onCancel, className: "close", children: "Close" }), _jsx("button", { onClick: createItem, className: "create", children: "Create" })] })] }));
}
const useCreateDialogItem = (listName) => {
    const itemProxy = useMemo(() => proxy({
        __metadata: {
            type: listName,
        },
    }), [listName]);
    const itemSnap = useSnapshot(itemProxy);
    return [itemSnap, itemProxy];
};
