import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import * as Select from '@radix-ui/react-select';
import { useCallback } from 'react';
import { createOpposingColor } from '../../utils';
import './choice-select.scss';
function ChoiceSelect({ id, placeholder, defaultValue, onChange, value, choices, idKey = 'Text', labelKey = 'Text', colorKey = 'AccentColour', }) {
    const statusCallbackRef = useCallback(function (ref) {
        const clr = choices?.find((c) => typeof c === 'string' ? c === value : c?.[idKey ?? 'id'] === value?.[idKey ?? 'id']);
        if (!clr && !!ref) {
            ref.style.setProperty('--chroma-color', 'var(--solid-background)');
            ref.style.setProperty('--chroma-bg-color', 'color-mix(in hsl, var(--solid-background) 20%, transparent)');
            return;
        }
        const color = typeof clr === 'string' ? clr : clr?.[colorKey];
        if (color && ref) {
            const chromaColor = createOpposingColor(color, color);
            ref.style.setProperty('--chroma-color', chromaColor.hex());
            ref.style.setProperty('--chroma-bg-color', color);
        }
    }, [value, idKey, colorKey]);
    const onChoiceChanged = useCallback(function (value) {
        onChange(choices?.find((c) => c[idKey ?? 'id'] === value));
    }, [onChange]);
    return (_jsx("div", { className: `choice-select${value ? ' selected' : ' unselected'}`, children: _jsxs(Select.Root, { value: value?.[idKey ?? 'id'] ?? '', defaultValue: defaultValue?.[idKey ?? 'id'], onValueChange: onChoiceChanged, children: [_jsxs(Select.Trigger, { id: id, ref: statusCallbackRef, className: `input-small trigger flex-align-row flex-between gap-normal p${value?.[idKey ?? 'id'] ? ' value' : ' placeholder'}`, children: [_jsxs(Select.SelectValue, { placeholder: placeholder, children: [!!value?.[idKey ?? 'id'] && value?.[idKey ?? 'id'], !value?.[idKey ?? 'id'] && placeholder] }), _jsx(Select.SelectIcon, { children: _jsx("i", { className: "fal fa-fw fa-angle-down" }) })] }), _jsxs(Select.Content, { className: "select-content", children: [_jsx(Select.ScrollUpButton, {}), _jsx(Select.Viewport, { className: "flex-column gap-normal", children: choices?.map((choice) => {
                                const label = typeof choice === 'string' ? choice : choice?.[labelKey];
                                const id = typeof choices === 'string' ? choice : choice?.[idKey];
                                return (_jsx(Select.Item, { value: id, className: "choice-item", onPointerDownCapture: (e) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        return e;
                                    }, children: _jsx(Select.ItemText, { children: _jsx("p", { className: "p truncate", children: label }) }) }, id));
                            }) }), _jsx(Select.ScrollDownButton, {})] })] }) }));
}
export default ChoiceSelect;
