import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { getListByName, store, useListItem } from '@rapid/sdk';
import './linked-items.scss';
import LinkedItemsGroup from './ui/linked-items-group/linked-items-group';
function LinkedItems(props) {
    const list = getListByName(props.item.__metadata.type);
    const [item, itemProxy] = useListItem(list, props.item.id);
    const onLinkedClicked = (listName) => {
        itemProxy.$linkedItems.fetchLinkedItemGroup(listName);
    };
    const openInRapid = (list, id) => {
        let url = store.auth.environments[store.auth.env]?.ui_react_url;
        url += `/${store.auth.tenant}/${store.auth.site}/explorer`;
        url += `/${list.ListName}/${list.ListNameSingular}:${list.ListName}:${id}`;
        window.open(url, '_blank');
    };
    return (_jsxs("section", { className: "linked-items flex-column", children: [_jsx("header", { className: "linked-items__header", children: _jsx("h4", { className: "h4", children: "Linked Items" }) }), _jsx("main", { className: "linked-items__container flex-column flex-fill", children: Object.entries(item.$linkedItems.items).map(([listName, items]) => {
                    return (_jsx(LinkedItemsGroup, { listName: listName, items: items, onOpen: onLinkedClicked, onNavigate: openInRapid }, listName));
                }) })] }));
}
export default LinkedItems;
