import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import DateDisplay from '../../../date-display/date-display';
import RapidDiffTable from '../rapid-diff-table/rapid-diff-table';
import { parseRapidCodeBlock, useHtmlFromString } from '../../utils';
import './timeline-item.scss';
function TimelineItem({ timeline, onMatch }) {
    const title = useHtmlFromString(timeline.title, onMatch);
    const body = useHtmlFromString(timeline.body ?? '', onMatch);
    const [diffType, diffTable] = parseRapidCodeBlock(timeline.body ?? '');
    const hasContent = !!body || !!diffType;
    return (_jsxs("section", { className: "timeline-item flex-column gap3", children: [_jsxs("header", { className: "timeline-item__header gap5", children: [_jsxs("span", { className: "flex-row gap5", children: [_jsx("i", { className: "timeline-item__type-icon fa fa-tasks" }), _jsx("h6", { className: "timeline-item__title h6 wrap", children: title })] }), _jsx(DateDisplay, { date: timeline.date })] }), hasContent && (_jsxs("main", { className: "timeline-item__content flex-column gap5", children: [_jsx("div", {}), diffType && _jsx(RapidDiffTable, { table: diffTable }), body && !diffType && _jsx("div", { className: "timeline-item__body", children: body })] }))] }));
}
export default TimelineItem;
