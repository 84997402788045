import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import DropZone from 'react-dropzone';
import { useSnapshot } from 'valtio';
import EmbeddedFile from './embedded-file';
import { toast } from 'react-toastify';
import { useCallback } from 'react';
export default function EmbeddedFileSection(props) {
    const snap = useSnapshot(props.embeddedFileSection);
    const checkFileSize = useCallback((file) => {
        if (file.size > 0) {
            return true;
        }
        toast.info('File must contain content');
        return false;
    }, []);
    const onUpload = (files) => {
        if (!!files.length && !snap.Multiple) {
            if (checkFileSize(files[0])) {
                props.embeddedFileSection.embedFile(files[0]);
            }
        }
        else if (!!files.length && !!snap.Multiple) {
            for (const file of files) {
                if (checkFileSize(file)) {
                    props.embeddedFileSection.embedFile(file);
                }
            }
        }
    };
    return (_jsxs("div", { className: "embedded-file-slot", children: [_jsxs("h5", { className: "embedded-file-slot__title", children: [_jsx("i", { className: `fal fa-fw fa-file${snap.Multiple ? 's' : ''}` }), snap?.Label] }), _jsx("div", { className: "embedded-file-slot__files", children: snap.Files?.map((file) => {
                    if (file.$state?.removed) {
                        return;
                    }
                    return (_jsx(EmbeddedFile, { section: props.embeddedFileSection, fileProxy: props.embeddedFileSection.Files.find((f) => f.$key === file.$key), file: file }, file.$key));
                }) }), (!!snap.Multiple || !snap.Files.filter((f) => !f.$state?.removed)?.length) && (_jsx(DropZone, { onDrop: onUpload, children: ({ getRootProps, getInputProps }) => (_jsxs("div", { ...getRootProps(), className: "embedded-file-slot__no-files", children: ["Click to choose a file, or drag a file into this area", _jsx("input", { ...getInputProps() })] })) }))] }));
}
