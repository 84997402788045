import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { defaultFormDocument } from '@rapid/adaptive-framework';
import { generateDesignerParams, store, useApplicationStore, useList, useProductUrl, } from '@rapid/sdk';
import { getListItem } from '@rapid/sdk/lib/store/list-item';
function generateConnectedDocument(list) {
    return {
        $type: 'Layout.Section',
        id: 'root',
        connection: {
            name: 'RAPID Platform',
            method: 'Single Item',
            args: {
                fetch: true,
                list: {
                    listName: list.ListName,
                    table: list.Table,
                },
            },
        },
    };
}
export default function NoDocumentFound(props) {
    const [list] = useList(props.listName);
    const [appData] = useApplicationStore();
    const { navigate } = useProductUrl('Designer');
    const openAdaptiveDesigner = async () => {
        const item = getListItem('Adaptive Documents');
        item.document = defaultFormDocument;
        item.document.type = 'page';
        item.document.head.title = `${list.ListName} - ${props.category}`;
        item.document.body = generateConnectedDocument(list);
        item.classification = 'Published';
        const res = await item.saveAsync();
        const newAppDocument = {
            adaptive_app_id: appData.adaptiveApp.id,
            adaptive_document_id: item.id,
            entity_type_id: list.TypeId,
            category: props.category,
        };
        await store.api.apps.postJson({}, newAppDocument);
        navigate(generateDesignerParams(res.id, ['design', 'overview'], 'overview'), '_blank');
    };
    return (_jsxs("div", { className: "no-document-found", children: [_jsx("h4", { className: "title", children: "We can't find that document" }), _jsx("h6", { className: "title", children: "Maybe you should make it?" }), _jsxs("button", { onClick: openAdaptiveDesigner, children: [_jsx("i", { className: "fak fa-fw fa-adaptive-document-light" }), "\u2002Adaptive Designer"] })] }));
}
