import { useMemo } from 'react';
import parseHtmlFromString from './ui/parse-html-from-string';
export function useHtmlFromString(str, onMatch) {
    const html = useMemo(() => parseHtmlFromString(str, onMatch), [str]);
    return html;
}
export function useOnMatch(match) {
    const onMatch = useMemo(() => match, []);
    return onMatch;
}
export function parseRapidCodeBlock(str) {
    const regex = /(\w+-?\w+).*?({.*})/gms;
    const match = regex.exec(str);
    try {
        if (match)
            return [match[1], JSON.parse(match[2])];
    }
    catch (e) {
        console.log(e);
    }
    return [null, null];
}
